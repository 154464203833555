/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import MetricsType from '../metrics/MetricsType';
import MetricType from '../metrics/MetricType';
import Durations from '../time/Duration';
import {ILogger} from '../logger/LoggerInterface';
import SDK from '../sdk/SDK';
import LoggerFactory from '../logger/LoggerFactory';

export class StreamSetupListener {
  private readonly _logger: ILogger = LoggerFactory.getLogger('StreamSetupListener');
  private readonly metricsService = SDK.metricsService;
  private readonly _pageLoadTime: number;
  private readonly _startTime: number;
  private _metricSubmitted = false;

  constructor(pageLoadTime: number) {
    this._startTime = Date.now();
    this._pageLoadTime = pageLoadTime;
  }

  success(streamId): void {
    this.recordStreamMetric(MetricsType.SetupCompleted, streamId);
  }

  fail(): void {
    this.recordStreamMetric(MetricsType.SetupFailed);
  }

  private recordStreamMetric(metricType: MetricsType, streamId?: string): void {
    if (this._metricSubmitted) {
      return ;
    }

    this._metricSubmitted = true;

    const now = Date.now();
    const setupTime = now - this._startTime;
    const metricName = new MetricType(metricType).getName();

    this.metricsService.push({
      metricType,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {uint64: setupTime},
      streamId
    });

    this._logger.info(
      '[%s] [%s] Stream metric [%s] in [%s]',
      new Durations(now - this._pageLoadTime).toIsoString(),
      streamId,
      metricName,
      new Durations(setupTime).toIsoString()
    );
  }
}