/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {LoggingLevel} from '../logger/Logger';
import LoggerDefaults from '../logger/LoggerDefaults';

export default class TelemetryConfiguration {
  private _url = 'https://telemetry.phenixrts.com';
  private _tenancy: string;
  private _sessionId: string;
  private _threshold = LoggerDefaults.defaultTelemetryLoggingLevel;

  get url(): string {
    return this._url;
  }

  set url(url: string) {
    this._url = url;
  }

  get tenancy(): string {
    return this._tenancy;
  }

  set tenancy(tenancy: string) {
    this._tenancy = tenancy;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  get threshold(): LoggingLevel {
    return this._threshold;
  }

  set threshold(threshold: LoggingLevel) {
    this._threshold = threshold;
  }
}