/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import IDisposable from '../lang/IDisposable';
import Disposable from '../lang/Disposable';
import IComparable from '../lang/IComparable';

class DisposableListener<T> extends Disposable {
  constructor(listeners: Array<T>, listener: T) {
    super(() => {
      const idx = listeners.indexOf(listener);

      if (idx >= 0) {
        listeners.splice(idx, 1);
      }
    });
  }
}

export default class Subject<T> {
  private readonly _listeners: Array<(T) => void>;
  private _value: T;

  constructor(value: T) {
    this._listeners = [];
    this._value = value;
  }

  set value(value: T) {
    let changed = this._value !== value;

    if (changed && value) {
      const comparable = value as unknown as IComparable<T>;

      if (typeof comparable.equals === 'function') {
        changed = !comparable.equals(this._value);
      }
    }

    if (!changed) {
      return;
    }

    this._value = value;

    this._listeners.forEach(listener => {
      listener(value);
    });
  }

  get value(): T {
    return this._value;
  }

  subscribe(listener: (T) => void): IDisposable {
    this._listeners.push(listener);

    listener(this._value);

    return new DisposableListener<(T) => void>(this._listeners, listener);
  }
}