/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import {ILogger} from '../../logger/LoggerInterface';

import {
  ISubscribeRequest,
  ISubscribeResponse,
  ISubscribeWithOfferRequest,
  ISubscribeWithoutOfferRequest
} from '../api/Subscribe';
import {ISetRemoteDescriptionRequest, ISetRemoteDescriptionResponse} from '../api/SetRemoteDescription';
import {ISessionDescription, SdpType} from '../api/SessionDescription';
import {IDestroyStreamRequest, IDestroyStreamResponse} from '../api/DestroyStream';
import assertUnreachable from '../../lang/assertUnreachable';
import {IAddIceCandidatesRequest, IAddIceCandidatesResponse} from '../api/AddIceCandidates';
import VersionManager from '../../version/VersionManager';
import EdgeAuth from '../edgeAuth/EdgeAuth';

const apiVersion = 6;

export type SubscribeStatus = 'ok' | 'no-stream' | 'not-found' | 'unauthorized' | 'geo-restricted' | 'geo-blocked' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type SetRemoteDescriptionStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type AddIceCandidatesStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export type DestroyStreamStatus = 'ok' | 'not-found' | 'unauthorized' | 'rate-limited' | 'capacity' | 'timeout' | 'failed';
export interface IStream {
  streamId: string;
  sharedSecret: string;
  tenancy: string;
}

interface ISubscribeResponseInit {
  status: SubscribeStatus;
  stream?: IStream;
  rtcConfiguration?: RTCConfiguration;
  setRemoteDescriptionResponse?: {
    sessionDescription: RTCSessionDescriptionInit;
  };
  createOfferDescriptionResponse?: {
    sessionDescription: RTCSessionDescriptionInit;
  };
  createAnswerDescriptionResponse?: {
    sessionDescription: RTCSessionDescriptionInit;
  };
}

interface ISetRemoteDescriptionResponseInit {
  status: SetRemoteDescriptionStatus;
  sessionDescription?: RTCSessionDescriptionInit;
}

interface IAddIceCandidatesResponseInit {
  status: AddIceCandidatesStatus;
  options?: string[];
}

interface IDestroyStreamResponseInit {
  status: DestroyStreamStatus;
}

export default class EndPoint {
  private readonly _logger: ILogger = LoggerFactory.getLogger('EndPoint');
  private readonly _uri: string;
  private readonly _timeout: number;
  private _roundTripTime: number;

  constructor(uri: string, timeout: number) {
    this._uri = uri;
    this._timeout = timeout;

    if (!timeout) {
      throw new Error(`End point requires a timeout`);
    }
  }

  get roundTripTime(): number {
    return this._roundTripTime;
  }

  toString(): string {
    return `EndPoint[uri=${this._uri}]`;
  }

  async ping(): Promise<number> {
    const url = this.buildPingUrl();
    const start = Date.now();
    const response = await Promise.race([
      fetch(url, {
        method: 'GET',
        cache: 'no-cache'
      }),
      new Promise<Response>((_, reject) =>
        setTimeout(() => reject(new Error(`Ping timed out [${url}]`)), this._timeout)
      )
    ]);
    const finished = Date.now();

    if (!response.ok) { /* Handle */
      throw new Error(`Ping failed [${url}] [${response.status}]`);
    }

    this._roundTripTime = finished - start;

    return this._roundTripTime;
  }

  async subscribe(token: string, localSessionDescription: RTCSessionDescriptionInit, failureCount: number): Promise<ISubscribeResponseInit> {
    const parsedToken = EdgeAuth.parseToken(token);

    if (!parsedToken || !parsedToken.applicationId) {
      this._logger.error('Failed to parse token [%s]', token);

      return {status: 'unauthorized'};
    }

    const tenancy = parsedToken.applicationId;
    const url = this.buildUrl([tenancy, 'stream', 'subscribe']).toString();
    let body: ISubscribeRequest;
    const clientVersion = VersionManager.sdkVersion;

    if (failureCount === 0 && localSessionDescription) {
      const bodyWithOffer: ISubscribeWithOfferRequest = {
        apiVersion,
        clientVersion,
        failureCount,
        setRemoteDescription: {
          apiVersion,
          sessionDescription: {
            type: this.convertRTCSdpTypeToSdpType(localSessionDescription.type),
            sdp: localSessionDescription.sdp
          }
        },
        createAnswerDescription: {apiVersion}
      };

      body = bodyWithOffer;
    } else {
      const bodyWithoutOffer: ISubscribeWithoutOfferRequest = {
        apiVersion,
        clientVersion,
        failureCount,
        createOfferDescription: {apiVersion}
      };

      body = bodyWithoutOffer;
    }

    const start = Date.now();
    let httpResponse: Response;

    try {
      httpResponse = await Promise.race([
        fetch(url, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }),
        new Promise<Response>((_, reject) =>
          setTimeout(() => reject(new Error(`Subscribe timed out [${url}]`)), this._timeout)
        )
      ]);
    } catch (e) {
      this._logger.error('Failed to subscribe', e);

      return {status: 'timeout'};
    }

    const status: SubscribeStatus = this.mapHttpStatusToSubscribeStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const subscribeResponse = await this.convertHttpResponseToSubscribeResponse(tenancy, httpResponse);

    this._logger.debug('Got subscribe response [%j] in [%s] ms', subscribeResponse, finished - start);

    return subscribeResponse;
  }

  async setRemoteDescription(stream: IStream, sessionDescription: RTCSessionDescriptionInit): Promise<ISetRemoteDescriptionResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId, 'description', 'remote']).toString();
    const body: ISetRemoteDescriptionRequest = {
      apiVersion,
      sessionDescription: {
        type: this.convertRTCSdpTypeToSdpType(sessionDescription.type),
        sdp: sessionDescription.sdp
      }
    };
    const start = Date.now();
    let httpResponse: Response;

    try {
      httpResponse = await Promise.race([
        fetch(url, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${stream.sharedSecret}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }),
        new Promise<Response>((_, reject) =>
          setTimeout(() => reject(new Error(`Set remote description timed out [${url}]`)), this._timeout)
        )
      ]);
    } catch (e) {
      this._logger.error('Failed to set remote description', e);

      return {status: 'timeout'};
    }

    const status: SetRemoteDescriptionStatus = this.mapHttpStatusToSetRemoteDescriptionStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const setRemoteDescriptionResponse = await this.convertHttpResponseToSetRemoteDescriptionResponse(httpResponse);

    this._logger.debug('Got set remote description response [%j] in [%s] ms', setRemoteDescriptionResponse, finished - start);

    return setRemoteDescriptionResponse;
  }

  async addIceCandidates(stream: IStream, candidates: RTCIceCandidate[], discoveryCompleted: boolean, options: string[] = []): Promise<IAddIceCandidatesResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId, 'ice', 'candidates']).toString();
    const body: IAddIceCandidatesRequest = {
      apiVersion,
      candidates,
      discoveryCompleted,
      options
    };
    const start = Date.now();
    let httpResponse: Response;

    try {
      httpResponse = await Promise.race([
        fetch(url, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${stream.sharedSecret}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }),
        new Promise<Response>((_, reject) =>
          setTimeout(() => reject(new Error(`Add ice candidates timed out [${url}]`)), this._timeout)
        )
      ]);
    } catch (e) {
      this._logger.error('Failed to add ice candidates', e);

      return {status: 'timeout'};
    }

    const status: AddIceCandidatesStatus = this.mapHttpStatusToAddIceCandidatesStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const addIceCandidatesResponse = await this.convertHttpResponseToAddIceCandidatesResponse(httpResponse);

    this._logger.info('Got add ICE candidates response [%j] in [%s] ms', addIceCandidatesResponse, finished - start);

    return addIceCandidatesResponse;
  }

  async destroyStream(stream: IStream, reason: string): Promise<IDestroyStreamResponseInit> {
    const url = this.buildUrl([stream.tenancy, 'stream', stream.streamId]).toString();
    const body: IDestroyStreamRequest = {
      apiVersion,
      reason,
      options: []
    };
    const start = Date.now();
    let httpResponse: Response;

    try {
      httpResponse = await Promise.race([
        fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${stream.sharedSecret}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body),
          cache: 'no-cache'
        }),
        new Promise<Response>((_, reject) =>
          setTimeout(() => reject(new Error(`Delete stream timed out [${url}]`)), this._timeout)
        )
      ]);
    } catch (e) {
      this._logger.error('Failed to delete stream', e);

      return {status: 'timeout'};
    }

    const status: DestroyStreamStatus = this.mapHttpStatusToSetDestroyStreamStatus(httpResponse);

    if (status !== 'ok') {
      return {status};
    }

    const finished = Date.now();
    const destroyStreamResponse = await this.convertHttpResponseToDestroyStreamResponse(httpResponse);

    this._logger.info('Got destroy stream response [%j] in [%s] ms', destroyStreamResponse, finished - start);

    return destroyStreamResponse;
  }

  buildUrl(path: string[]): URL {
    const uri = new URL(this._uri);
    const pathAsArray = uri.pathname.split('/');

    pathAsArray.length = pathAsArray.length - 1;

    uri.pathname = pathAsArray.concat(...path).join('/');

    return uri;
  }

  private buildPingUrl(): string {
    const uri = new URL(this._uri);
    const sdkVersion = VersionManager.sdkVersion;

    uri.search = `?${new URLSearchParams([['type', 'http'], ['version', sdkVersion], ['_', `${Date.now()}`]]).toString()}`;

    return uri.toString();
  }

  private mapHttpStatusToSubscribeStatus(response: Response): SubscribeStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 402:
        return 'geo-restricted';
      case 403:
        return 'geo-blocked';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToSetRemoteDescriptionStatus(response: Response): SetRemoteDescriptionStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToAddIceCandidatesStatus(response: Response): AddIceCandidatesStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private mapHttpStatusToSetDestroyStreamStatus(response: Response): DestroyStreamStatus {
    if (!response) {
      return 'failed';
    }

    switch (response.status) {
      case 200:
        return 'ok';
      case 401:
        return 'unauthorized';
      case 408:
        return 'timeout';
      case 503:
        return 'capacity';
      case 504:
        return 'rate-limited';
      default:
        return 'failed';
    }
  }

  private async convertHttpResponseToSubscribeResponse(tenancy: string, response: Response): Promise<ISubscribeResponseInit> {
    const data = await response.json() as ISubscribeResponse;
    const subscribeResponse: ISubscribeResponseInit = {status: data.status};

    subscribeResponse.stream = {
      tenancy,
      streamId: data.streamId,
      sharedSecret: data.sharedSecret
    };

    if (data) {
      if (data.rtcConfiguration) {
        const rtcConfiguration: RTCConfiguration = {};

        if (data.rtcConfiguration.bundlePolicy) {
          switch (data.rtcConfiguration.bundlePolicy) {
            case 'BundlePolicyBalanced':
              rtcConfiguration.bundlePolicy = 'balanced';

              break;
            case 'BundlePolicyMaxCompat':
              rtcConfiguration.bundlePolicy = 'max-compat';

              break;
            case 'BundlePolicyMaxBundle':
              rtcConfiguration.bundlePolicy = 'max-bundle';

              break;
            default:
              assertUnreachable(data.rtcConfiguration.bundlePolicy);
          }
        }

        if (typeof data.rtcConfiguration.iceCandidatePoolSize === 'number') {
          rtcConfiguration.iceCandidatePoolSize = data.rtcConfiguration.iceCandidatePoolSize;
        }

        if (data.rtcConfiguration.iceServers) {
          const iceServers: RTCIceServer[] = [];

          for (let i = 0; i < data.rtcConfiguration.iceServers.length; i++) {
            iceServers.push({
              urls: data.rtcConfiguration.iceServers[i].urls,
              username: data.rtcConfiguration.iceServers[i].username,
              credential: data.rtcConfiguration.iceServers[i].credential
            });
          }

          rtcConfiguration.iceServers = iceServers;
        }

        if (data.rtcConfiguration.iceTransportPolicy) {
          switch (data.rtcConfiguration.iceTransportPolicy) {
            case 'IceTransportPolicyAll':
              rtcConfiguration.iceTransportPolicy = 'all';

              break;
            case 'IceTransportPolicyRelay':
              rtcConfiguration.iceTransportPolicy = 'relay';

              break;
            case 'IceTransportPolicyPublic':
              // Deprecated - Not supported
              break;
            default:
              assertUnreachable(data.rtcConfiguration.iceTransportPolicy);
          }
        }

        if (data.rtcConfiguration.peerIdentity) {
          rtcConfiguration.peerIdentity = data.rtcConfiguration.peerIdentity;
        }

        if (data.rtcConfiguration.rtcpMuxPolicy) {
          switch (data.rtcConfiguration.rtcpMuxPolicy) {
            case 'RtcpMuxPolicyNegotiate':
              rtcConfiguration.rtcpMuxPolicy = 'negotiate';

              break;
            case 'RtcpMuxPolicyRequire':
              rtcConfiguration.rtcpMuxPolicy = 'require';

              break;
            default:
              assertUnreachable(data.rtcConfiguration.rtcpMuxPolicy);
          }
        }

        subscribeResponse.rtcConfiguration = rtcConfiguration;
      }

      if (data.setRemoteDescriptionResponse && data.setRemoteDescriptionResponse.sessionDescription) {
        subscribeResponse.setRemoteDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.setRemoteDescriptionResponse.sessionDescription)};
      }

      if (data.createAnswerDescriptionResponse && data.createAnswerDescriptionResponse.sessionDescription) {
        subscribeResponse.createAnswerDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.createAnswerDescriptionResponse.sessionDescription)};
      }

      if (data.createOfferDescriptionResponse && data.createOfferDescriptionResponse.sessionDescription) {
        subscribeResponse.createOfferDescriptionResponse = {sessionDescription: this.convertISessionDescriptionToRTCSessionDescription(data.createOfferDescriptionResponse.sessionDescription)};
      }
    }

    return subscribeResponse;
  }

  private convertISessionDescriptionToRTCSessionDescription(sessionDescription: ISessionDescription): RTCSessionDescriptionInit {
    const rtcSessionDescription: RTCSessionDescriptionInit = {sdp: sessionDescription.sdp};

    switch (sessionDescription.type) {
      case 'Offer':
        rtcSessionDescription.type = 'offer';

        break;
      case 'Answer':
        rtcSessionDescription.type = 'answer';

        break;
      default:
        assertUnreachable(sessionDescription.type);
    }

    return rtcSessionDescription;
  }

  private async convertHttpResponseToSetRemoteDescriptionResponse(response: Response): Promise<ISetRemoteDescriptionResponseInit> {
    const data = await response.json() as ISetRemoteDescriptionResponse;
    const setRemoteDescriptionResponse: ISetRemoteDescriptionResponseInit = {status: data.status};

    if (data && data.sessionDescription) {
      setRemoteDescriptionResponse.sessionDescription = this.convertISessionDescriptionToRTCSessionDescription(data.sessionDescription);
    }

    return setRemoteDescriptionResponse;
  }

  private async convertHttpResponseToAddIceCandidatesResponse(response: Response): Promise<IAddIceCandidatesResponseInit> {
    const data = await response.json() as IAddIceCandidatesResponse;
    const addIceCandidatesResponse: IAddIceCandidatesResponseInit = {
      status: data.status,
      options: []
    };

    if (data) {
      if (data.options) {
        addIceCandidatesResponse.options = data.options;
      }
    }

    return addIceCandidatesResponse;
  }

  private async convertHttpResponseToDestroyStreamResponse(response: Response): Promise<IDestroyStreamResponseInit> {
    const data = await response.json() as IDestroyStreamResponse;
    const destroyStream: IDestroyStreamResponseInit = {status: data.status};

    return destroyStream;
  }

  private convertRTCSdpTypeToSdpType(type: RTCSdpType): SdpType {
    switch (type) {
      case 'answer':
        return 'Answer';
      case 'offer':
        return 'Offer';
      case 'pranswer':
      case 'rollback':
        throw new Error(`SDP type [${type}] is not supported`);
      default:
        assertUnreachable(type);
    }
  }
}