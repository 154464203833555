/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import VersionManager from '../version/VersionManager';
import MetricsConfiguration from './MetricsConfiguration';
import MetricType from './MetricType';
import MetricsType from './MetricsType';

const requestSizeLimit = 1024;

interface IValue {
  string?: string;
  uint64?: number;
  float?: number;
}

interface IMetricRecord {
  timestamp: string;
  tenancy: string;
  sessionId: string;
  streamId: string;
  metric: string;
  value: IValue;
  previousValue: IValue;
  fullQualifiedName: string;
  environment: string;
  version: string;
  runtime: number;
  resource?: string;
  kind?: string;
}

interface IMetric {
  streamId?: string;
  metricType: MetricsType;
  value?: IValue;
  previousValue?: IValue;
  runtime: number;
  resource?: string;
  kind?: string;
}

export default class MetricsService {
  private readonly _metricsConfiguration: MetricsConfiguration;
  private _metrics: Array<IMetricRecord> = [];
  private _isSending: boolean;
  private _domain = location.hostname;

  constructor(metricsConfiguration: MetricsConfiguration) {
    this._metricsConfiguration = metricsConfiguration;
  }

  push(metric: IMetric): void {
    const {streamId, value, previousValue, runtime, resource, kind} = metric;
    const metricType = new MetricType(metric.metricType);

    if (this._metricsConfiguration.threshold > metricType.getTelemetryLevel()) {
      return;
    }

    const metricRecord = {
      timestamp: new Date().toISOString(),
      tenancy: this._metricsConfiguration.tenancy,
      sessionId: this._metricsConfiguration.sessionId,
      streamId,
      metric: metricType.getName(),
      value,
      previousValue,
      fullQualifiedName: this._domain,
      environment: '',
      version: VersionManager.sdkVersion,
      runtime,
      resource,
      kind
    };

    this._metrics.push(metricRecord);

    const ignored = this.sendMetricsIfAble();
  }

  private async sendMetrics(body: string): Promise<Response | void> {
    return await fetch(`${this._metricsConfiguration.url}/telemetry/metrics`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body
    });
  }

  private async sendMetricsIfAble(): Promise<Response | void> {
    if (this._metrics.length <= 0 || this._isSending) {
      return;
    }

    this._isSending = true;

    const body = JSON.stringify({records: this._metrics.slice(0, requestSizeLimit)});

    this._metrics = this._metrics.slice(requestSizeLimit);

    return this.sendMetrics(body).then(response => {
      this._isSending = false;

      const ignored = this.sendMetricsIfAble();

      return response;
    }).catch(() => {
      this._isSending = false;

      const ignored = this.sendMetricsIfAble();
    });
  }
}