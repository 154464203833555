/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
declare const __PRIVACY_MODE__: boolean;

export default class PrivacyMode {
  private static _isPrivate: boolean;

  static get isPrivate(): boolean {
    return this._isPrivate;
  }

  static applyIsPrivate(): void {
    try {
      this._isPrivate = __PRIVACY_MODE__;
    } catch (e) {
      this._isPrivate = false;
    }
  }
}

PrivacyMode.applyIsPrivate();