/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import Channel from './Channel';

interface ICreateChannelOptions {
    videoElement: HTMLVideoElement;
    token: string;
    targetLag?: number;
}

export default class Channels {
  static createChannel(options: ICreateChannelOptions): Channel {
    return new Channel(options.videoElement, options.token, options.targetLag);
  }

  private constructor() {
    throw new Error('Channels is a static class that may not be instantiated');
  }
}