/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import EndPoint from './EndPoint';
import SDK from '../SDK';
import {ILogger} from '../../logger/LoggerInterface';
import VersionManager from '../../version/VersionManager';
import MetricsType from '../../metrics/MetricsType';

const defaultTimeout = 20000;
const discoveryCacheInterval = 60000;

interface IPerURIEndpoint { [x: string]: Promise<EndPoint> }

export default class Discovery {
  private static _logger: ILogger = LoggerFactory.getLogger('Discovery');
  private static _metricsService = SDK.metricsService;
  private static _cache: IPerURIEndpoint = {};

  static async precacheClosestEndPointDiscovery(): Promise<EndPoint> {
    const uri = new URL(SDK.discoveryUri.value);

    return Discovery.discoverClosestEndPointWithCaching(uri);
  }

  static async discoverClosestEndPointWithCaching(uri: URL): Promise<EndPoint> {
    const url = uri.toString();

    if (Discovery._cache[url]) {
      return Discovery._cache[url];
    }

    const cachedValue = Discovery._cache[url] = Discovery.discoverClosestEndPoint(uri);

    Discovery._cache[url].then(() => {
      const ignored = setTimeout(() => {
        if (Discovery._cache[url] === cachedValue) {
          delete Discovery._cache[url];
        }
      }, discoveryCacheInterval);
    }).catch((e) => {
      delete Discovery._cache[url];

      throw e;
    });

    return cachedValue;
  }

  static async discoverNearbyEndPoints(uri: URL, timeout: number): Promise<EndPoint[]> {
    if (!uri) {
      throw new Error('Discovery requires uri');
    }

    if (!timeout) {
      throw new Error('Discovery requires timeout');
    }

    const url = uri.toString();
    const response = await Promise.race([
      fetch(url, {
        method: 'GET',
        cache: 'no-cache'
      }),
      new Promise<Response>((_, reject) =>
        setTimeout(() => reject(new Error(`Discovery timed out [${url}]`)), timeout)
      )
    ]);

    if (!response.ok) {
      throw new Error(`Discovery failed [${url}] [${response.status}]`);
    }

    if (response.body === null) {
      throw new Error(`Discovery failed with no data [${url}]`);
    }

    const asString = await response.text();
    const endPoints = asString.split(',');

    return endPoints.map((endPoint) => new EndPoint(endPoint, timeout));
  }

  static async discoverClosestEndPoint(uri: URL, timeout: number = defaultTimeout): Promise<EndPoint> {
    const url = this.buildDiscoveryUrl(uri);
    const endPoints = await this.discoverNearbyEndPoints(new URL(url), timeout);
    const neverResolve = (): Promise<void> => new Promise(() => {
      this._logger.info('Request [%s] failed, preventing it from completing', url);
    });
    const endPoint = await Promise.race(endPoints.map(endPoint => endPoint
      .ping()
      .catch(e => {
        this._logger.warn('Failed to ping end point [%s]', endPoint, e);

        return neverResolve();
      })
      .then((time) => {
        const now = Date.now();

        this._logger.info('Discovered end point [%s] with time [%s]', endPoint.toString(), time);
        this._metricsService.push({
          metricType: MetricsType.RoundTripTime,
          runtime: (now - SDK.pageLoadTime) / 1000,
          value: {uint64: time || 0},
          resource: endPoint.toString(),
          kind: 'ping'
        });

        return endPoint;
      })));

    return endPoint;
  }

  private static buildDiscoveryUrl(uri: URL): string {
    const url = new URL(uri.toString());
    const sdkVersion = VersionManager.sdkVersion;

    url.search = `?${new URLSearchParams([['version', sdkVersion], ['_', `${Date.now()}`]]).toString()}`;

    if (url.pathname === '/') {
      url.pathname = '/pcast/endPoints';
    }

    return url.toString();
  }

  private constructor() {
    throw new Error('Discovery is a static class that may not be instantiated');
  }
}

const ignored = Discovery.precacheClosestEndPointDiscovery();