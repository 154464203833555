/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {TelemetryLevel, TelemetryLevelType} from './MetricsConfiguration';
import assertUnreachable from '../lang/assertUnreachable';

export default class TelemetryLevelMapping {
  static convertTelemetryLevelToTelemetryLevelType(telemetryLevel: TelemetryLevel): TelemetryLevelType {
    switch (telemetryLevel) {
      case TelemetryLevel.Off:
        return 'Off';
      case TelemetryLevel.Essential:
        return 'Essential';
      case TelemetryLevel.All:
        return 'All';
      default:
        assertUnreachable(telemetryLevel);
    }
  }

  static convertTelemetryLevelTypeToTelemetryLevel(telemetryLevelType: TelemetryLevelType): TelemetryLevel {
    switch (telemetryLevelType) {
      case 'Off':
        return TelemetryLevel.Off;
      case 'Essential':
        return TelemetryLevel.Essential;
      case 'All':
        return TelemetryLevel.All;
      default:
        assertUnreachable(telemetryLevelType);
    }
  }
}