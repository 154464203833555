/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

export default class Durations {
  private readonly _duration: number;

  constructor(duration = 0) {
    this._duration = duration;
  }

  toIsoString(): string {
    if (this._duration === 0) {
      return 'P0S';
    }

    const isNegative = this._duration < 0;
    const duration = isNegative ? Math.abs(this._duration) : this._duration;
    let offset = Math.floor(duration);
    const milliseconds = offset % 1000;

    offset = Math.floor(offset / 1000);

    const seconds = offset % 60;

    offset = Math.floor(offset / 60);

    const minutes = offset % 60;

    offset = Math.floor(offset / 60);

    const hours = offset % 24;
    const parts = ['PT'];

    if (isNegative) {
      parts.unshift('-');
    }

    if (hours) {
      parts.push(hours + 'H');
    }

    if (minutes) {
      parts.push(minutes + 'M');
    }

    if (seconds || milliseconds) {
      parts.push(String(seconds));

      if (milliseconds) {
        parts.push('.' + milliseconds.toString().padStart(3, '0'));
      }

      parts.push('S');
    }

    return parts.join('');
  }
}