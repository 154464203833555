/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import Strings from '../lang/Strings';
import {defaultDiscoveryUri} from './defaults';
import Subject from '../rx/Subject';
import ReadOnlySubject from '../rx/ReadOnlySubject';
import IPeerConnectionFactory from '../rtc/IPeerConnectionFactory';
import VanillaPeerConnectionFactory from '../rtc/VanillaPeerConnectionFactory';
import EdgeAuth from './edgeAuth/EdgeAuth';
import {ILogger} from '../logger/LoggerInterface';
import {LoggingLevel, LoggingLevelInputTypes} from '../logger/Logger';
import ConsoleAppender from '../logger/ConsoleAppender';
import TelemetryAppender from '../telemetry/TelemetryApender';
import ConfigurationParameterReader from '../dom/ConfigurationParameterReader';
import MetricsConfiguration, {TelemetryLevel, TelemetryLevelType} from '../metrics/MetricsConfiguration';
import MetricsService from '../metrics/MetricsService';
import TelemetryLevelMapping from '../metrics/TelemetricLevelMapping';
import LoggingLevelMapping from '../logger/LoggingLevelMapping';
import PrivacyMode from '../dom/PrivacyMode';
import BrowserDetector from '../dom/BrowserDetector';
import {IConfigurationParameterReader} from '../dom/IConfigurationParamaterReader';
import ApplicationActivityMonitor from '../dom/ApplicationActivityMonitor';

const pageLoadTime = window['__phenixPageLoadTime'] || window['__pageLoadTime'] || Date.now();

interface IInitOptions {
  discoveryUri?: string;
  peerConnectionFactory?: IPeerConnectionFactory;
  telemetryLevel?: TelemetryLevelType;
  loggingLevel?: LoggingLevelInputTypes;
  consoleLoggingLevel?: LoggingLevelInputTypes;
  automaticallyPlayMediaStream: boolean;
  automaticallyMuteVideoOnPlayFailure: boolean;
}

export default class SDK {
  private static _automaticallyRetryOnFailure = true;
  private static _automaticallyPlayMediaStream = true;
  private static _automaticallyMuteVideoOnPlayFailure = true;
  private static _configurationParameterReader: IConfigurationParameterReader = new ConfigurationParameterReader();
  private static _metricsConfiguration: MetricsConfiguration = new MetricsConfiguration()
  private static _metricsService: MetricsService = new MetricsService(SDK._metricsConfiguration);
  private static _telemetryUrl: Subject<string> = new Subject<string>('https://telemetry.phenixrts.com');
  private static readonly _sendLocalCandidates: Subject<boolean> = new Subject(!PrivacyMode.isPrivate);
  private static readonly _tenancy: Subject<string> = new Subject<string>('');
  private static readonly _clientSessionId: string = Strings.random(32);
  private static readonly _loadedTimestamp: Date = new Date();
  private static readonly _logger: ILogger = LoggerFactory.getLogger('SDK');
  private static readonly _applicationActivityMonitor: ApplicationActivityMonitor = new ApplicationActivityMonitor();
  private static readonly _initialized: Subject<boolean> = new Subject<boolean>(false);
  private static readonly _discoveryUri: Subject<string> = new Subject<string>(defaultDiscoveryUri);
  private static readonly _peerConnectionFactory: Subject<IPeerConnectionFactory> = new Subject<IPeerConnectionFactory>(new VanillaPeerConnectionFactory());
  private static readonly _readOnlyInitialized: ReadOnlySubject<boolean> = new ReadOnlySubject<boolean>(SDK._initialized);
  private static readonly _readOnlyDiscoveryUri: ReadOnlySubject<string> = new ReadOnlySubject<string>(SDK._discoveryUri);
  private static readonly _readOnlyPeerConnectionFactory: ReadOnlySubject<IPeerConnectionFactory> = new ReadOnlySubject<IPeerConnectionFactory>(SDK._peerConnectionFactory);

  static get pageLoadTime(): number {
    return pageLoadTime;
  }

  static get sendLocalCandidates(): Subject<boolean> {
    return this._sendLocalCandidates;
  }

  static get tenancy(): Subject<string> {
    return this._tenancy;
  }

  static get clientSessionId(): string {
    return SDK._clientSessionId;
  }

  static get loadedTimestamp(): Date {
    return SDK._loadedTimestamp;
  }

  static get applicationActivityMonitor(): ApplicationActivityMonitor {
    return this._applicationActivityMonitor;
  }

  static get initialized(): ReadOnlySubject<boolean> {
    return SDK._readOnlyInitialized;
  }

  static get discoveryUri(): ReadOnlySubject<string> {
    return SDK._readOnlyDiscoveryUri;
  }

  static get peerConnectionFactory(): ReadOnlySubject<IPeerConnectionFactory> {
    return SDK._readOnlyPeerConnectionFactory;
  }

  static get automaticRetryOnFailure(): boolean {
    return SDK._automaticallyRetryOnFailure;
  }

  static get automaticallyPlayMediaStream(): boolean {
    return SDK._automaticallyPlayMediaStream;
  }

  static get automaticallyMuteVideoOnPlayFailure(): boolean {
    return SDK._automaticallyMuteVideoOnPlayFailure;
  }

  static get metricsService(): MetricsService {
    return SDK._metricsService;
  }

  static get telemetryUrl(): Subject<string> {
    return this._telemetryUrl;
  }

  static get loggingLevel(): LoggingLevelInputTypes {
    return LoggingLevelMapping.convertLoggingLevelToLoggingLevelType(this._logger.threshold.value);
  }

  static get telemetryLevel(): TelemetryLevelType {
    return TelemetryLevelMapping.convertTelemetryLevelToTelemetryLevelType(SDK._metricsConfiguration.threshold);
  }

  static get browserDetector(): BrowserDetector {
    return BrowserDetector;
  }

  static applyTelemetryConfiguration(): void {
    const telemetryConfiguration = LoggerFactory.telemetryConfiguration;

    telemetryConfiguration.sessionId = SDK.clientSessionId;

    const ignoredDiscoveryUri = SDK.telemetryUrl.subscribe((value) => {
      const telemetryAppender = this._logger.appenders.value.find((appender) => appender instanceof TelemetryAppender);

      if (telemetryAppender) {
        telemetryConfiguration.url = value;

        this._logger.appenders.remove(telemetryAppender);
        this._logger.appenders.add(new TelemetryAppender(telemetryConfiguration));
        this._logger.info('Telemetry URL was set to [%s]', telemetryConfiguration.url);
      }
    });
    const ignoredTenancy = SDK._tenancy.subscribe((tenancy) => {
      const telemetryAppender = this._logger.appenders.value.find((appender) => appender instanceof TelemetryAppender);

      if (telemetryAppender && tenancy) {
        telemetryConfiguration.tenancy = tenancy;

        this._logger.appenders.remove(telemetryAppender);
        this._logger.appenders.add(new TelemetryAppender(telemetryConfiguration));
        this._logger.info('Telemetry tenancy was set to [%s]', telemetryConfiguration.tenancy);
      }
    });
  }

  static applyMetricsConfiguration(): void {
    SDK._metricsConfiguration.sessionId = SDK.clientSessionId;

    const ignoredDiscoveryUri = SDK.telemetryUrl.subscribe((value) => {
      SDK._metricsConfiguration.url = value;
    });
    const ignoredTenancy = SDK._tenancy.subscribe((tenancy) => {
      SDK._metricsConfiguration.tenancy = tenancy;
    });
    const value = this._configurationParameterReader.getStringValue('phenix-metrics-level');

    if (value) {
      SDK._metricsConfiguration.threshold = TelemetryLevel[value];
    }
  }

  static applyAutomaticallyRetryOnFailureFromParameterConfiguration(): void {
    this._automaticallyRetryOnFailure = this._configurationParameterReader.getBooleanValue('phenix-automatically-retry-on-failure');
    this._logger.info('Automatically retry on failure is set to: [%s]', this._automaticallyRetryOnFailure);

    return;
  }

  static applyDiscoveryUriDefaultFromParameterConfiguration(): void {
    SDK._discoveryUri.subscribe((value) => {
      SDK.telemetryUrl.value = SDK.getTelemetryUrl(value);
    });

    const channelToken = this._configurationParameterReader.getStringValue('phenix-channel-token');

    if (channelToken) {
      const parsedToken = EdgeAuth.parseToken(channelToken);

      SDK._tenancy.value = EdgeAuth.getTenancy(parsedToken);
      SDK._discoveryUri.value = (EdgeAuth.getUri(parsedToken) || SDK.discoveryUri.value).toString();
      this._logger.info('Discovery URI set from configuration parameter to [%s]', SDK._discoveryUri.value);

      return;
    }

    const uriValue = this._configurationParameterReader.getStringValue('phenix-uri');

    if (uriValue) {
      SDK._discoveryUri.value = uriValue;
      this._logger.info('Discovery URI set from "phenix-uri" configuration parameter tag to [%s]', SDK._discoveryUri.value);

      return;
    }

    const baseURIValue = this._configurationParameterReader.getStringValue('phenix-base-uri');

    if (baseURIValue) {
      SDK._discoveryUri.value = `${baseURIValue}/pcast/endPoints`;
      this._logger.info('Discovery URI set from "phenix-base-uri" configuration parameter tag to [%s]', SDK._discoveryUri.value);

      return;
    }
  }

  static init(options?: IInitOptions): void {
    if (SDK._initialized.value) {
      throw new Error('SDK is already initialized');
    }

    if (options) {
      if (options.discoveryUri) {
        SDK._discoveryUri.value = options.discoveryUri;
      }

      if (options.peerConnectionFactory) {
        SDK._peerConnectionFactory.value = options.peerConnectionFactory;
      }

      if (options.telemetryLevel && TelemetryLevel[options.telemetryLevel]) {
        SDK._metricsConfiguration.threshold = TelemetryLevelMapping.convertTelemetryLevelTypeToTelemetryLevel(options.telemetryLevel);
      }

      if (options.loggingLevel && LoggingLevel[options.loggingLevel]) {
        this._logger.threshold.setThreshold(LoggingLevelMapping.convertLoggingLevelTypeToLoggingLevel(options.loggingLevel));
      }

      if (options.consoleLoggingLevel &&
        LoggingLevel[options.consoleLoggingLevel]) {
        const consoleAppender = this._logger.appenders.value.find((appender) => appender instanceof ConsoleAppender);

        if (consoleAppender) {
          this._logger.appenders.remove(consoleAppender);
        }

        if (options.consoleLoggingLevel !== 'Off') {
          this._logger.appenders.add(new ConsoleAppender(LoggingLevelMapping.convertLoggingLevelTypeToLoggingLevel(options.consoleLoggingLevel)));
        }
      }

      if (typeof options.automaticallyPlayMediaStream === 'boolean') {
        this._automaticallyPlayMediaStream = options.automaticallyPlayMediaStream;
      }

      if (typeof options.automaticallyMuteVideoOnPlayFailure === 'boolean') {
        this._automaticallyMuteVideoOnPlayFailure = options.automaticallyMuteVideoOnPlayFailure;
      }
    }

    SDK._initialized.value = true;
  }

  static dispose(): void {
    SDK._initialized.value = false;
  }

  static getTelemetryUrl(url: string): string {
    const uri = new URL(url);

    if (uri.origin.includes('local')) {
      return 'https://local.phenixrts.com:8443';
    }

    if (uri.origin.includes('stg')) {
      return 'https://telemetry-stg.phenixrts.com';
    }

    return 'https://telemetry.phenixrts.com';
  }

  private constructor() {
    throw new Error('SDK is a static class that may not be instantiated');
  }
}

SDK.applyDiscoveryUriDefaultFromParameterConfiguration();
SDK.applyMetricsConfiguration();
SDK.applyTelemetryConfiguration();
SDK.applyAutomaticallyRetryOnFailureFromParameterConfiguration();