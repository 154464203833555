/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {StreamSetupListener} from '../dom/StreamSetupListener';
import {ILogger} from '../logger/LoggerInterface';
import LoggerFactory from '../logger/LoggerFactory';
import NetworkMonitor from '../dom/NetworkMonitor';
import SDK from '../sdk/SDK';
import MetricsType from '../metrics/MetricsType';
import Durations from '../time/Duration';
import IDisposable from '../lang/IDisposable';

interface INetworkStatistics {
  rtt?: number;
  effectiveType?: string;
  downlinkThroughputCapacity?: number;
}

export default class SessionTelemetry {
  private readonly _logger: ILogger = LoggerFactory.getLogger('SessionTelemetry');
  private readonly _metricsService = SDK.metricsService;
  private readonly _applicationActivityMonitor = SDK.applicationActivityMonitor;
  private readonly _networkMonitor: NetworkMonitor = new NetworkMonitor();
  private readonly _pageLoadTime: number;
  private readonly _disposables: IDisposable[] = [];
  private _lastNetworkStatistics: INetworkStatistics = {};

  constructor(pageLoadTime: number) {
    this._pageLoadTime = pageLoadTime;

    if (!this._networkMonitor.isSupported) {
      return;
    }

    const isForegroundListener = this._applicationActivityMonitor.isForeground.subscribe((isForeground) => {
      this.recordForegroundChange(isForeground);
    });
    const rttListener = this._networkMonitor.rtt.subscribe((value) => {
      this.recordNetworkRTTUpdate(value);
    });
    const effectiveTypeListener = this._networkMonitor.effectiveType.subscribe((value) => {
      this.recordNetworkTypeChangeUpdate(value);
    });
    const downlinkThroughputCapacityListener = this._networkMonitor.downlinkThroughputCapacity.subscribe((value) => {
      this.recordNetworkDownlinkThroughputCapacityUpdate(value);
    });

    this._disposables.push(isForegroundListener, rttListener, effectiveTypeListener, downlinkThroughputCapacityListener);
  }

  listenOnStreamSetup(): StreamSetupListener {
    return new StreamSetupListener(this._pageLoadTime);
  }

  close(): void {
    this._disposables.forEach(disposable => disposable.dispose());
    this._disposables.length = 0;
  }

  private recordForegroundChange(isForeground: boolean): void {
    const now = Date.now();
    const timeSinceLastChange = this._applicationActivityMonitor.getTimeSinceLastChange();

    this._metricsService.push({
      metricType: isForeground ? MetricsType.ApplicationForeground : MetricsType.ApplicationBackground,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {uint64: timeSinceLastChange}
    });

    this._logger.info(
      'Application has gone into the [%s] after [%s] ms',
      isForeground ? 'foreground' : 'background',
      new Durations(timeSinceLastChange).toIsoString(),
    );
  }

  private recordNetworkRTTUpdate(newRTT: number): void {
    const now = Date.now();
    const oldRtt = this._lastNetworkStatistics.rtt;

    this._lastNetworkStatistics.rtt = newRTT;

    this._metricsService.push({
      metricType: MetricsType.RoundTripTime,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {uint64: newRTT},
      previousValue: oldRtt ? {uint64: oldRtt} : undefined,
      resource: 'navigator'
    });

    this._logger.info(
      '[%s] Network RTT changed to [%s] from [%s]',
      new Durations(now - this._pageLoadTime).toIsoString(),
      newRTT,
      oldRtt
    );
  }

  private recordNetworkTypeChangeUpdate(newNetworkType: string): void {
    const now = Date.now();
    const oldNetworkType = this._lastNetworkStatistics.effectiveType;

    this._lastNetworkStatistics.effectiveType = newNetworkType;

    this._metricsService.push({
      metricType: MetricsType.NetworkType,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {string: newNetworkType},
      previousValue: oldNetworkType ? {string: oldNetworkType} : undefined
    });

    this._logger.info(
      '[%s] Network effective type has changed to [%s] from [%s]',
      new Durations(now - this._pageLoadTime).toIsoString(),
      newNetworkType,
      oldNetworkType
    );
  }

  private recordNetworkDownlinkThroughputCapacityUpdate(newCapacity: number): void {
    const now = Date.now();
    const oldCapacity = this._lastNetworkStatistics.downlinkThroughputCapacity;

    this._lastNetworkStatistics.downlinkThroughputCapacity = newCapacity;

    this._metricsService.push({
      metricType: MetricsType.DownlinkThroughputCapacity,
      runtime: (now - this._pageLoadTime) / 1000,
      value: {float: newCapacity},
      previousValue: oldCapacity ? {float: oldCapacity} : undefined
    });

    this._logger.info(
      '[%s] Network downlink throughput capacity changed to [%s] from [%s]',
      new Durations(now - this._pageLoadTime).toIsoString(),
      newCapacity,
      oldCapacity
    );
  }
}