/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import IDisposable from '../lang/IDisposable';
import Disposable from '../lang/Disposable';
import Subject from '../rx/Subject';

export default class NetworkMonitor {
  private readonly _disposables: IDisposable[] = [];
  private readonly _isSupported = navigator && navigator['connection'];
  private readonly _rtt: Subject<number> = new Subject(undefined);
  private readonly _effectiveType: Subject<string> = new Subject(undefined);
  private readonly _downlinkThroughputCapacity: Subject<number> = new Subject(undefined);

  constructor() {
    if (!this.isSupported) {
      return;
    }

    this.setupNetworkChangeListeners();
    this.updateStatistics();
  }

  get isSupported(): boolean {
    return this._isSupported;
  }

  get rtt(): Subject<number> {
    return this._rtt;
  }

  get effectiveType(): Subject<string> {
    return this._effectiveType;
  }

  get downlinkThroughputCapacity(): Subject<number> {
    return this._downlinkThroughputCapacity;
  }

  close(): void {
    this._disposables.forEach(disposable => disposable.dispose());
    this._disposables.length = 0;
  }

  private getDownlinkThroughputCapacity(): number {
    if (!this.isSupported) {
      return -1;
    }

    return navigator['connection'].downlink || navigator['connection'].downlinkMax;
  }

  private getEffectiveType(): string {
    if (!this.isSupported) {
      return 'Unknown';
    }

    return navigator['connection'].effectiveType || navigator['connection'].type;
  }

  private getRoundTripTime(): number {
    return navigator['connection'].rtt;
  }

  private setupNetworkChangeListeners(): void {
    const listener = (): void => {
      this.updateStatistics();
    };

    navigator['connection'].addEventListener('change', listener);

    this._disposables.push(new Disposable(() => {
      navigator['connection'].removeEventListener('change', listener);
    }));
  }

  private updateStatistics(): void {
    this._rtt.value = this.getRoundTripTime();
    this._effectiveType.value = this.getEffectiveType();
    this._downlinkThroughputCapacity.value = this.getDownlinkThroughputCapacity();
  }
}