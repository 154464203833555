/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
export default class BrowserDetector {
  private static readonly _browserNameAndVersionRegex = /(MSIE|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari|(?!AppleWebKit.+)Chrome|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d.apre]+)/g;
  private static readonly _browserNameAndVersion = BrowserDetector.parseBrowserNameAndVersion();
  private static readonly _browserName = BrowserDetector.parseBrowserName();
  private static readonly _browserMajorVersion = BrowserDetector.parseBrowserMajorVersion();

  private static readonly _isChrome69 = BrowserDetector._browserNameAndVersion.includes('Chrome/69.');
  private static readonly _isChrome70 = BrowserDetector._browserNameAndVersion.includes('Chrome/70.');
  private static readonly _isChrome71 = BrowserDetector._browserNameAndVersion.includes('Chrome/71.');
  private static readonly _isChrome72 = BrowserDetector._browserNameAndVersion.includes('Chrome/72.');
  private static readonly _isChrome74 = BrowserDetector._browserNameAndVersion.includes('Chrome/74.');
  private static readonly _isChrome75 = BrowserDetector._browserNameAndVersion.includes('Chrome/75.');
  private static readonly _isChrome76 = BrowserDetector._browserNameAndVersion.includes('Chrome/76.');
  private static readonly _isChrome77 = BrowserDetector._browserNameAndVersion.includes('Chrome/77.');

  static get browserNameAndVersion(): string {
    return BrowserDetector._browserNameAndVersion;
  }

  static get browserName(): string {
    return this._browserName;
  }

  static get browserMajorVersion(): number {
    return this._browserMajorVersion;
  }

  static get isChrome69(): boolean {
    return BrowserDetector._isChrome69;
  }

  static get isChrome70(): boolean {
    return BrowserDetector._isChrome70;
  }

  static get isChrome71(): boolean {
    return BrowserDetector._isChrome71;
  }

  static get isChrome72(): boolean {
    return BrowserDetector._isChrome72;
  }

  static get isChrome74(): boolean {
    return BrowserDetector._isChrome74;
  }

  static get isChrome75(): boolean {
    return BrowserDetector._isChrome75;
  }

  static get isChrome76(): boolean {
    return BrowserDetector._isChrome76;
  }

  static get isChrome77(): boolean {
    return BrowserDetector._isChrome77;
  }

  private static parseBrowserNameAndVersion(): string {
    if (!navigator || !navigator.userAgent) {
      return '';
    }

    const browserNameAndVersion = navigator.userAgent.match(BrowserDetector._browserNameAndVersionRegex)[0];

    if (!browserNameAndVersion) {
      return '';
    }

    return browserNameAndVersion;
  }

  private static parseBrowserName(): string {
    return BrowserDetector._browserNameAndVersion.split('/')[0] || '';
  }

  private static parseBrowserMajorVersion(): number {
    const fullVersion = BrowserDetector._browserNameAndVersion.split('/')[1];

    if (!fullVersion) {
      return 0;
    }

    return Number(fullVersion.split('.')[0]) || 0;
  }
}