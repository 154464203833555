/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import IComparable from '../lang/IComparable';

export default class Dimension implements IComparable<Dimension> {
  readonly width: number;
  readonly height: number;

  static get empty(): Dimension {
    return new Dimension(0, 0);
  }

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  equals(other: Dimension): boolean {
    return other && this.width === other.width && this.height === other.height;
  }

  toString(): string {
    return `${this.width}x${this.height}`;
  }
}