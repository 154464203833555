/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerDefaults from './LoggerDefaults';
import {LoggingLevel} from './Logger';

export default class LoggingThreshold {
  private _threshold: LoggingLevel = LoggerDefaults.defaultLoggingLevel;

  get value(): LoggingLevel {
    return this._threshold;
  }

  setThreshold(threshold: LoggingLevel): void {
    this._threshold = threshold;
  }
}