/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../../logger/LoggerFactory';
import IDisposable from '../../lang/IDisposable';
import Subject from '../../rx/Subject';
import ReadOnlySubject from '../../rx/ReadOnlySubject';
import Dimension from '../../video/Dimension';
import Discovery from '../discovery/Discovery';
import EndPoint, {IStream, SetRemoteDescriptionStatus, SubscribeStatus} from '../discovery/EndPoint';
import SDK from '../SDK';
import IPeerConnection from '../../rtc/IPeerConnection';
import ChannelState from './ChannelState';
import assertUnreachable from '../../lang/assertUnreachable';
import Disposable from '../../lang/Disposable';
import EdgeAuth from '../edgeAuth/EdgeAuth';
import PeerConnectionService, {IPeerConnectionOfferInit} from '../../rtc/PeerConnectionService';
import {ILogger} from '../../logger/LoggerInterface';
import VideoTelemetry from '../../video/VideoTelemetry';
import SessionTelemetry from '../../video/SessionTelemetry';
import FeatureEnablement from '../../dom/FeatureEnablement';
import RtcConnectionMonitor, {IRtcStatistic} from '../../dom/RtcConnectionMonitor';

const defaultTargetLag = 0;
const defaultStreamSetupTimeout = 30000;
const defaultStreamTerminationReason = 'client:termination';
const iceCandidateAccumulationInterval = 100;
const backoffIntervalInMilliseconds = 2000;
const maxBackoffIntervalInMilliseconds = 300000;
const standbyPollingIntervalInMilliseconds = 15000;

export default class Channel implements IDisposable {
  private readonly _logger: ILogger = LoggerFactory.getLogger('Channel');
  private readonly _disposables: IDisposable[];
  private readonly _videoElement: Subject<HTMLVideoElement>;
  private readonly _token: Subject<string>;
  private readonly _peerConnection: Subject<IPeerConnection>;
  private readonly _mediaStream: Subject<MediaStream>;
  private readonly _state: Subject<ChannelState>;
  private readonly _autoMuted: Subject<boolean>;
  private readonly _autoPaused: Subject<boolean>;
  private readonly _tokenExpiring: Subject<boolean>;
  private readonly _authorized: Subject<boolean>;
  private readonly _online: Subject<boolean>;
  private readonly _loading: Subject<boolean>;
  private readonly _playing: Subject<boolean>;
  private readonly _standby: Subject<boolean>;
  private readonly _stopped: Subject<boolean>;
  private readonly _targetLag: Subject<number>;
  private readonly _lag: Subject<number>;
  private readonly _resolution: Subject<Dimension>;
  private readonly _failureCount: Subject<number>;
  private readonly _endPoint: Subject<EndPoint>;
  private readonly _stream: Subject<IStream>;
  private readonly _rtcStatistics: Subject<IRtcStatistic>;
  private readonly _readOnlyVideoElement: ReadOnlySubject<HTMLVideoElement>;
  private readonly _readOnlyToken: ReadOnlySubject<string>;
  private readonly _readOnlyPeerConnection: ReadOnlySubject<IPeerConnection>;
  private readonly _readOnlyState: ReadOnlySubject<ChannelState>;
  private readonly _readOnlyAutoMuted: ReadOnlySubject<boolean>;
  private readonly _readOnlyAutoPaused: ReadOnlySubject<boolean>;
  private readonly _readOnlyTokenExpiring: ReadOnlySubject<boolean>;
  private readonly _readOnlyAuthorized: ReadOnlySubject<boolean>;
  private readonly _readOnlyOnline: ReadOnlySubject<boolean>;
  private readonly _readOnlyLoading: ReadOnlySubject<boolean>;
  private readonly _readOnlyPlaying: ReadOnlySubject<boolean>;
  private readonly _readOnlyStandby: ReadOnlySubject<boolean>;
  private readonly _readOnlyStopped: ReadOnlySubject<boolean>;
  private readonly _readOnlyTargetLag: ReadOnlySubject<number>;
  private readonly _readOnlyLag: ReadOnlySubject<number>;
  private readonly _readOnlyResolution: ReadOnlySubject<Dimension>;
  private readonly _readOnlyFailureCount: ReadOnlySubject<number>;
  private readonly _readOnlyEndPoint: ReadOnlySubject<EndPoint>;
  private readonly _readOnlyStream: ReadOnlySubject<IStream>;

  private readonly _videoMetaDataChangedHandler: () => void;
  private _videoTelemetry: VideoTelemetry;
  private _sessionTelemetry: SessionTelemetry;
  private _rtcAudioStatistic: IRtcStatistic;
  private _rtcVideoStatistic: IRtcStatistic;

  constructor(videoElement: HTMLVideoElement, token: string, targetLag: number = defaultTargetLag) {
    this._disposables = [];
    this._videoElement = new Subject<HTMLVideoElement>(null);
    this._token = new Subject<string>(token);
    this._peerConnection = new Subject<IPeerConnection>(null);
    this._mediaStream = new Subject<MediaStream>(null);
    this._state = new Subject<ChannelState>(ChannelState.Starting);
    this._autoMuted = new Subject<boolean>(false);
    this._autoPaused = new Subject<boolean>(false);
    this._tokenExpiring = new Subject<boolean>(false);
    this._authorized = new Subject<boolean>(true);
    this._online = new Subject<boolean>(true);
    this._loading = new Subject<boolean>(false);
    this._playing = new Subject<boolean>(false);
    this._standby = new Subject<boolean>(false);
    this._stopped = new Subject<boolean>(false);
    this._targetLag = new Subject<number>(targetLag);
    this._lag = new Subject<number>(0);
    this._resolution = new Subject<Dimension>(Dimension.empty);
    this._failureCount = new Subject<number>(0);
    this._endPoint = new Subject<EndPoint>(null);
    this._stream = new Subject<IStream>(null);
    this._rtcStatistics = new Subject<IRtcStatistic>(null);

    this._readOnlyVideoElement = new ReadOnlySubject<HTMLVideoElement>(this._videoElement);
    this._readOnlyToken = new ReadOnlySubject<string>(this._token);
    this._readOnlyPeerConnection = new ReadOnlySubject<IPeerConnection>(this._peerConnection);
    this._readOnlyState = new ReadOnlySubject<ChannelState>(this._state);
    this._readOnlyAutoMuted = new ReadOnlySubject<boolean>(this._autoMuted);
    this._readOnlyAutoPaused = new ReadOnlySubject<boolean>(this._autoPaused);
    this._readOnlyTokenExpiring = new ReadOnlySubject<boolean>(this._tokenExpiring);
    this._readOnlyAuthorized = new ReadOnlySubject<boolean>(this._authorized);
    this._readOnlyOnline = new ReadOnlySubject<boolean>(this._online);
    this._readOnlyLoading = new ReadOnlySubject<boolean>(this._loading);
    this._readOnlyPlaying = new ReadOnlySubject<boolean>(this._playing);
    this._readOnlyStandby = new ReadOnlySubject<boolean>(this._standby);
    this._readOnlyStopped = new ReadOnlySubject<boolean>(this._stopped);
    this._readOnlyTargetLag = new ReadOnlySubject<number>(this._targetLag);
    this._readOnlyLag = new ReadOnlySubject<number>(this._lag);
    this._readOnlyResolution = new ReadOnlySubject<Dimension>(this._resolution);
    this._readOnlyFailureCount = new ReadOnlySubject<number>(this._failureCount);
    this._readOnlyEndPoint = new ReadOnlySubject<EndPoint>(this._endPoint);
    this._readOnlyStream = new ReadOnlySubject<IStream>(this._stream);

    const parsedToken = EdgeAuth.parseToken(this._token.value);

    SDK.tenancy.value = EdgeAuth.getTenancy(parsedToken) || SDK.tenancy.value;
    SDK.telemetryUrl.value = SDK.getTelemetryUrl((EdgeAuth.getUri(parsedToken) || SDK.discoveryUri.value).toString());

    this.stream.subscribe((stream) => {
      if (this._videoTelemetry) {
        this._videoTelemetry.close();
      }

      if (!stream) {
        return;
      }

      this._videoTelemetry = new VideoTelemetry(this.streamId, SDK.pageLoadTime);
      this._videoTelemetry.setupListenerForTimeToFirstTime(this.videoElement);
      this._videoTelemetry.setupListenerForRebuffering(this.videoElement);
    });

    this._sessionTelemetry = new SessionTelemetry(SDK.pageLoadTime);

    this._videoMetaDataChangedHandler = this.handleVideoMetaDataChanged.bind(this);
    this.videoElement = videoElement;

    this.start();

    this._resolution.subscribe((resolution) => {
      if (this._videoTelemetry) {
        this._videoTelemetry.onVideoResolutionChanges(resolution.toString());
      }
    });
  }

  get videoElement(): HTMLVideoElement {
    return this._videoElement.value;
  }

  set videoElement(videoElement: HTMLVideoElement) {
    if (this._videoElement.value) {
      this._videoElement.value.removeEventListener('loadeddata', this._videoMetaDataChangedHandler);
      this._videoElement.value.removeEventListener('loadedmetadata', this._videoMetaDataChangedHandler);
    }

    this._videoElement.value = videoElement;

    if (this._videoElement.value) {
      this._videoElement.value.addEventListener('loadeddata', this._videoMetaDataChangedHandler);
      this._videoElement.value.addEventListener('loadedmetadata', this._videoMetaDataChangedHandler);
    }
  }

  private handleVideoMetaDataChanged(): void {
    const videoElement = this._videoElement.value;

    if (videoElement) {
      this._resolution.value = new Dimension(videoElement.videoWidth, videoElement.videoHeight);
      this._failureCount.value = 0;
    } else {
      this._resolution.value = Dimension.empty;
    }
  }

  get token(): string {
    return this._token.value;
  }

  set token(token: string) {
    this._token.value = token;
    this._tokenExpiring.value = false;

    const parsedToken = EdgeAuth.parseToken(this._token.value);

    SDK.tenancy.value = EdgeAuth.getTenancy(parsedToken) || SDK.tenancy.value;
    SDK.telemetryUrl.value = SDK.getTelemetryUrl((EdgeAuth.getUri(parsedToken) || SDK.discoveryUri.value).toString());
  }

  get peerConnection(): ReadOnlySubject<IPeerConnection> {
    return this._readOnlyPeerConnection;
  }

  get state(): ReadOnlySubject<ChannelState> {
    return this._readOnlyState;
  }

  get autoMuted(): ReadOnlySubject<boolean> {
    return this._readOnlyAutoMuted;
  }

  get autoPaused(): ReadOnlySubject<boolean> {
    return this._readOnlyAutoPaused;
  }

  get tokenExpiring(): ReadOnlySubject<boolean> {
    return this._readOnlyTokenExpiring;
  }

  get authorized(): ReadOnlySubject<boolean> {
    return this._readOnlyAuthorized;
  }

  get online(): ReadOnlySubject<boolean> {
    return this._readOnlyOnline;
  }

  get loading(): ReadOnlySubject<boolean> {
    return this._readOnlyLoading;
  }

  get playing(): ReadOnlySubject<boolean> {
    return this._readOnlyPlaying;
  }

  get standby(): ReadOnlySubject<boolean> {
    return this._readOnlyStandby;
  }

  get stopped(): ReadOnlySubject<boolean> {
    return this._readOnlyStopped;
  }

  get targetLag(): ReadOnlySubject<number> {
    return this._readOnlyTargetLag;
  }

  get lag(): ReadOnlySubject<number> {
    return this._readOnlyLag;
  }

  get resolution(): ReadOnlySubject<Dimension> {
    return this._readOnlyResolution;
  }

  get failureCount(): ReadOnlySubject<number> {
    return this._readOnlyFailureCount;
  }

  get endPoint(): ReadOnlySubject<EndPoint> {
    return this._readOnlyEndPoint;
  }

  get stream(): ReadOnlySubject<IStream> {
    return this._readOnlyStream;
  }

  get streamId(): string {
    const stream = this._readOnlyStream.value;

    if (!stream) {
      return '-';
    }

    return stream.streamId;
  }

  get rtcStats(): Subject<IRtcStatistic> {
    return this._rtcStatistics;
  }

  updateTargetLag(lag: number): void {
    this._lag.value = lag;
  }

  stop(reason: string): void {
    if (this._videoElement.value) {
      this._videoElement.value.pause();
      this._videoElement.value.srcObject = null;
    }

    this.cleanUpResources(reason);

    this._state.value = ChannelState.Stopped;
  }

  async resume(): Promise<void> {
    if (this._mediaStream.value) {
      this._autoPaused.value = false;

      return this.playMediaStreamInVideoElement(this._mediaStream.value);
    }
  }

  mute(): void {
    const videoElement = this._videoElement.value;

    if (videoElement) {
      videoElement.muted = true;
    }
  }

  unmute(): void {
    const videoElement = this._videoElement.value;

    if (videoElement) {
      videoElement.muted = false;
      this._autoMuted.value = false;
    }
  }

  dispose(): void {
    this.stop('client:channel-dispose');
  }

  getUri(token): URL {
    const parsedToken = EdgeAuth.parseToken(token);
    const url = EdgeAuth.getUri(parsedToken);

    if (url) {
      return url;
    }

    this._logger.info('Fall back to the default discover URI [%s]', SDK.discoveryUri.value);

    return new URL(SDK.discoveryUri.value);
  }

  async start(): Promise<void> {
    const token = this._token.value;
    const listenOnStreamSetup = this._sessionTelemetry.listenOnStreamSetup();

    if (!EdgeAuth.isValidToken(token)) {
      this._logger.error('Failed to parse token [%s]', token);
      this._state.value = ChannelState.Unauthorized;
      this._authorized.value = false;

      return;
    }

    this.cleanUpResources('client:start');
    this._state.value = ChannelState.Starting;
    this._loading.value = true;

    const uri = this.getUri(token);

    return Promise.all<EndPoint, IPeerConnectionOfferInit>([
      Discovery.discoverClosestEndPointWithCaching(uri),
      PeerConnectionService.createPeerConnectionOffer()
    ])
      .then(([endPoint, {localOffer, peerConnection}]) => {
        this._online.value = true;
        this._endPoint.value = endPoint;
        this._logger.info('Connecting to [%s]', endPoint.toString());
        this._logger.info('Local offer:\n' + localOffer.sdp);

        if (FeatureEnablement.clientOfferDisabled || !peerConnection.supportsSetConfiguration || !peerConnection.supportsGetConfiguration) {
          peerConnection.close();
          peerConnection = null;
          localOffer = null;
        }

        return endPoint.subscribe(token, localOffer, this._failureCount.value)
          .then(({status, stream, rtcConfiguration, setRemoteDescriptionResponse, createOfferDescriptionResponse, createAnswerDescriptionResponse}) => {
            this._stream.value = stream;

            if (this.videoElement && this.videoElement.dataset) {
              this.videoElement.dataset.sessionId = SDK.clientSessionId;
              this.videoElement.dataset.streamId = this.streamId;
            }

            this._logger.debug(
              '[%s] Subscribe completed [%s] [%j] [%j] [%j] [%j]',
              this.streamId,
              status,
              rtcConfiguration,
              setRemoteDescriptionResponse,
              createOfferDescriptionResponse,
              createAnswerDescriptionResponse
            );

            this._state.value = this.mapSubscribeStatusToChannelStatus(status);

            switch (status) {
              case 'ok':
                break;
              case 'unauthorized':
              case 'geo-restricted':
              case 'geo-blocked':
                this._authorized.value = false;

              // eslint-disable-next-line no-fallthrough
              case 'no-stream':
              case 'not-found':
                this._failureCount.value = 0;
                this._playing.value = false;
                this._standby.value = true;
                this._stopped.value = false;
                this._stream.value = null;

                return;
              default:
                this._failureCount.value++;
                this._playing.value = false;
                this._standby.value = true;
                this._stopped.value = false;
                this._stream.value = null;
                this._state.value = ChannelState.Error;

                return;
            }

            return this.applyRtcConfiguration(peerConnection, rtcConfiguration)
              .then((peerConnection) => {
                let submitCandidatesTimeout;
                let cancelDiscovery = false;
                let discoveryCompleted = false;
                const candidates: RTCIceCandidate[] = [];

                this._peerConnection.value = peerConnection;

                peerConnection.onicecandidate = (e): void => {
                  if (this._stream.value !== stream) {
                    return;
                  }

                  if (this._peerConnection.value !== peerConnection) {
                    return;
                  }

                  if (cancelDiscovery) {
                    return;
                  }

                  if (!SDK.sendLocalCandidates.value) {
                    return;
                  }

                  if (e.candidate && e.candidate.candidate) {
                    candidates.push(e.candidate);
                  } else {
                    discoveryCompleted = true;
                  }

                  if (!submitCandidatesTimeout) {
                    submitCandidatesTimeout = setTimeout(() => {
                      if (this._stream.value !== stream) {
                        return;
                      }

                      if (cancelDiscovery) {
                        return;
                      }

                      const ignored = endPoint.addIceCandidates(stream, candidates, discoveryCompleted)
                        .then(({status, options}) => {
                          if (status !== 'ok') {
                            this._logger.warn('[%s] Failed to add ICE candidates with reason [%s]', this.streamId, status);

                            return;
                          }

                          if (options.includes('cancel')) {
                            cancelDiscovery = true;
                          }

                          this._logger.info('[%s] Added ICE candidates with reason [%s] and options [%s]', this.streamId, status, options);
                        })
                        .catch(e => {
                          this._logger.error('[%s] Failed to add ICE candidates', this.streamId, e);
                        });
                    }, iceCandidateAccumulationInterval);
                  }
                };

                peerConnection.oniceconnectionstatechange = (): void => {
                  if (this._stream.value !== stream) {
                    return;
                  }

                  if (this._peerConnection.value !== peerConnection) {
                    return;
                  }

                  switch (peerConnection.iceConnectionState) {
                    case 'checking':
                    case 'completed':
                    case 'connected':
                    case 'new':
                      return;

                    case 'closed':
                    case 'disconnected':
                    case 'failed':
                      // If we stop normally the peer connection is unregistered first.
                      // Thus anytime we see a closed peer connection that is still valid, it is an error.
                      this._state.value = ChannelState.Error;

                      break;
                    default:
                      assertUnreachable(peerConnection.iceConnectionState);
                  }

                  if (this._videoElement.value) {
                    this._videoElement.value.pause();
                    this._videoElement.value.srcObject = null;
                  }

                  this._playing.value = false;
                  this._loading.value = true;

                  const ignored = this.retryStart()
                    .catch(e => {
                      this._logger.error(
                        '[%s] Failed to retry start after peer connection stopped with state [%s]',
                        this.streamId,
                        peerConnection.iceConnectionState,
                        e
                      );
                    });
                };

                const mediaStreamPromise = new Promise<MediaStream>((resolve, reject) => {
                  if (!FeatureEnablement.onTrackDisabled) {
                    const timeoutId = setTimeout(() => reject(new Error('Stream setup timed out')), defaultStreamSetupTimeout);

                    peerConnection.ontrack = (e): void => {
                      clearTimeout(timeoutId);

                      resolve(e.streams[0]);
                    };

                    return;
                  }

                  const trackListener = (e): void => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    clearTimeout(timeoutId);
                    peerConnection.removeEventListener('track', trackListener);
                    peerConnection.removeEventListener('addstream', trackListener);

                    if (e.streams) {
                      resolve(e.streams[0]);
                    } else {
                      resolve(e.stream);
                    }
                  };

                  const timeoutId = setTimeout(() => {
                    peerConnection.removeEventListener('track', trackListener);
                    peerConnection.removeEventListener('addstream', trackListener);
                    reject(new Error('Stream setup timed out'));
                  }, defaultStreamSetupTimeout);

                  peerConnection.addEventListener('track', trackListener);
                  peerConnection.addEventListener('addstream', trackListener);

                  return;
                });

                return new Promise((resolve) => {
                  resolve();
                }).then(() => {
                  if (!setRemoteDescriptionResponse) {
                    return;
                  }

                  this._logger.info('[%s] Set local SDP offer [%s]', this.streamId, setRemoteDescriptionResponse.sessionDescription.sdp);

                  return peerConnection.setLocalDescription(setRemoteDescriptionResponse.sessionDescription);
                }).then(() => {
                  if (!createAnswerDescriptionResponse) {
                    return;
                  }

                  this._logger.info('[%s] Set remote SDP answer [%s]', this.streamId, createAnswerDescriptionResponse.sessionDescription.sdp);

                  return peerConnection.setRemoteDescription(createAnswerDescriptionResponse.sessionDescription);
                }).then(() => {
                  if (!createOfferDescriptionResponse) {
                    return;
                  }

                  this._logger.info('[%s] Set remote SDP offer [%s]', this.streamId, createOfferDescriptionResponse.sessionDescription.sdp);

                  return peerConnection.setRemoteDescription(createOfferDescriptionResponse.sessionDescription)
                    .then(() => {
                      return peerConnection.createAnswer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true
                      });
                    }).then(answer => {
                      this._logger.info('[%s] Set local SDP answer [%j]', this.streamId, answer);

                      return endPoint.setRemoteDescription(stream, answer);
                    }).then(({status, sessionDescription}) => {
                      this._state.value = this.mapSetRemoteDescriptionStatusToChannelStatus(status);

                      if (status !== 'ok') {
                        this._playing.value = false;
                        this._standby.value = true;
                        this._stopped.value = false;

                        return;
                      }

                      return peerConnection.setLocalDescription(sessionDescription);
                    });
                }).then(() => {
                  listenOnStreamSetup.success(this.streamId);

                  const rtcConnectionMonitor = new RtcConnectionMonitor(peerConnection, endPoint.roundTripTime / 4);

                  this._disposables.push(rtcConnectionMonitor);

                  const ignored = rtcConnectionMonitor.rtcStatistic.subscribe((statistics) => {
                    this._rtcStatistics.value = statistics;

                    if (!this._rtcVideoStatistic && !this._rtcAudioStatistic) {
                      this._rtcAudioStatistic = statistics.audio;
                      this._rtcVideoStatistic = statistics.video;

                      return;
                    }

                    let audioTrackFailed = false;
                    let videoTrackFailed = false;

                    if (statistics.audio) {
                      if (this._rtcAudioStatistic && this._rtcAudioStatistic.timestamp !== statistics.audio.timestamp) {
                        audioTrackFailed = this._rtcAudioStatistic && this._rtcAudioStatistic.bytesReceived === statistics.audio.bytesReceived;
                        this._rtcAudioStatistic = statistics.audio;
                      }
                    }

                    if (statistics.video) {
                      if (this._rtcVideoStatistic && this._rtcVideoStatistic.timestamp !== statistics.video.timestamp) {
                        videoTrackFailed = this._rtcVideoStatistic && this._rtcVideoStatistic.bytesReceived === statistics.video.bytesReceived;
                        this._rtcVideoStatistic = statistics.video;
                      }
                    }

                    if (videoTrackFailed || audioTrackFailed) {
                      this._state.value = ChannelState.Error;

                      if (this._videoElement.value) {
                        this._videoElement.value.pause();
                        this._videoElement.value.srcObject = null;
                      }

                      this._playing.value = false;
                      this._loading.value = true;

                      rtcConnectionMonitor.dispose();

                      const ignored = this.retryStart()
                        .catch(e => {
                          this._logger.error(
                            '[%s] Failed to retry start after track stopped with state [%s]',
                            this.streamId,
                            peerConnection.iceConnectionState,
                            e
                          );
                        });
                    }
                  });

                  return mediaStreamPromise;
                }).then(mediaStream => {
                  this._mediaStream.value = mediaStream;

                  if (!SDK.automaticallyPlayMediaStream) {
                    this._autoMuted.value = false;
                    this._autoPaused.value = true;
                    this._loading.value = false;
                    this._playing.value = false;
                    this._state.value = ChannelState.Paused;

                    return;
                  }

                  return this.playMediaStreamInVideoElement(mediaStream);
                });
              });
          });
      })
      .then(() => {
        this._loading.value = false;
      })
      .catch(e => {
        listenOnStreamSetup.fail();

        this._failureCount.value++;

        this._online.value = false;

        this.cleanUpResources('client:cleanup-after-failed-setup');

        this._state.value = ChannelState.Error;

        this._logger.error('Failed to discover end point, marking as offline', e);
      })
      .finally(() => {
        if (this._state.value === ChannelState.Playing || !SDK.automaticRetryOnFailure) {
          return;
        }

        const timeoutId = setTimeout(() => {
          const ignored = this.retryStart()
            .catch(e => {
              this._logger.error('Failed to retry start', e);
            });
        }, this.getRetryInterval());

        this._disposables.push(new Disposable(() => {
          clearTimeout(timeoutId);
        }));
      });
  }

  public async play(): Promise<void> {
    const mediaStream = this._mediaStream.value;

    if (!mediaStream) {
      return this.start();
    }

    return this.playMediaStreamInVideoElement(mediaStream);
  }

  private getRetryInterval(): number {
    switch (this._state.value) {
      case ChannelState.StandBy:
      case ChannelState.Offline:
        return standbyPollingIntervalInMilliseconds;
      case ChannelState.Error:
      case ChannelState.Recovering:
      case ChannelState.Unauthorized:
      case ChannelState.GeoRestricted:
      case ChannelState.GeoBlocked:
      case ChannelState.Stopped:
      case ChannelState.Starting:
      case ChannelState.Playing:
      case ChannelState.Paused:
        // First and second attempt fast, after that exponential with backoff interval
        return Math.min(maxBackoffIntervalInMilliseconds, Math.pow(backoffIntervalInMilliseconds, Math.max(0, this._failureCount.value - 1)));
      default:
        assertUnreachable(this._state.value);
    }
  }

  private async retryStart(): Promise<void> {
    switch (this._state.value) {
      case ChannelState.Error:
      case ChannelState.StandBy:
      case ChannelState.Offline:
      case ChannelState.Recovering:
        this._logger.info('Retry start with initial state [%s] [%s]', this._state.value, ChannelState[this._state.value]);

        break;
      case ChannelState.Unauthorized:
        this._logger.info('Channel is unauthorized, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.GeoRestricted:
        this._logger.info('Channel is geo restricted, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.GeoBlocked:
        this._logger.info('Channel is geo blocked, skipping retry of start. Please provide a new token and invoke start()');

        return;
      case ChannelState.Stopped:
        this._logger.info('Channel is stopped, skipping retry of start.');

        return;
      case ChannelState.Playing:
        this._logger.info('Channel is playing, skipping retry of start');

        return;
      case ChannelState.Paused:
        this._logger.info('Channel is paused, skipping retry of start. Please invoke play()');

        return;
      case ChannelState.Starting:
        this._logger.info('Channel is already starting, skipping retry of start');

        return;
      default:
        assertUnreachable(this._state.value);
    }

    return this.start();
  }

  private cleanUpResources(reason: string = defaultStreamTerminationReason): void {
    this._disposables.forEach(disposable => disposable.dispose());
    this._disposables.length = 0;

    const peerConnection = this._peerConnection.value;

    if (peerConnection) {
      this._peerConnection.value = null;
      peerConnection.close();
    }

    if (this._mediaStream.value) {
      this._mediaStream.value.getTracks().forEach(track => track.stop());
      this._mediaStream.value = null;
    }

    this._autoPaused.value = false;
    this._autoMuted.value = false;
    this._playing.value = false;
    this._stopped.value = true;
    this._standby.value = false;

    if (this._stream.value && this._endPoint.value) {
      const ignored = this._endPoint.value.destroyStream(this._stream.value, reason)
        .then(({status}) => {
          if (status !== 'ok') {
            this._logger.warn('[%s] Failed to destroy stream with reason [%s]', this.streamId, status);

            return;
          }

          this._logger.info('[%s] Destroyed stream with reason [%s]', this.streamId, status);
        })
        .catch(e => {
          this._logger.error('[%s] Failed to destroy stream', this.streamId, e);
        });
    }

    if (this.videoElement && this.videoElement.dataset) {
      this.videoElement.dataset.sessionId = '';
      this.videoElement.dataset.streamId = '';
    }

    this._stream.value = null;
    this._endPoint.value = null;
  }

  private mapSubscribeStatusToChannelStatus(status: SubscribeStatus): ChannelState {
    switch (status) {
      case 'ok':
        return ChannelState.Starting;
      case 'no-stream':
      case 'not-found':
        return ChannelState.StandBy;
      case 'geo-restricted':
        return ChannelState.GeoRestricted;
      case 'geo-blocked':
        return ChannelState.GeoBlocked;
      case 'unauthorized':
        return ChannelState.Unauthorized;
      case 'capacity':
      case 'rate-limited':
      case 'timeout':
        return ChannelState.Recovering;
      case 'failed':
        return ChannelState.Error;
      default:
        assertUnreachable(status);
    }
  }

  private mapSetRemoteDescriptionStatusToChannelStatus(status: SetRemoteDescriptionStatus): ChannelState {
    switch (status) {
      case 'ok':
        return ChannelState.Starting;
      case 'unauthorized':
        return ChannelState.Unauthorized;
      case 'not-found':
      case 'capacity':
      case 'rate-limited':
      case 'timeout':
        return ChannelState.Recovering;
      case 'failed':
        return ChannelState.Error;
      default:
        assertUnreachable(status);
    }
  }

  private async applyRtcConfiguration(
    optionalPeerConnection: IPeerConnection | null,
    rtcConfiguration: RTCConfiguration): Promise<IPeerConnection> {
    if (!optionalPeerConnection) {
      rtcConfiguration = this.truncateIceServers(rtcConfiguration);

      return SDK.peerConnectionFactory.value.createPeerConnection(rtcConfiguration);
    }

    const newRtcConfiguration = {
      ...optionalPeerConnection.getConfiguration(),
      ...rtcConfiguration
    };

    optionalPeerConnection.setConfiguration(newRtcConfiguration);

    return optionalPeerConnection;
  }

  private async playMediaStreamInVideoElement(mediaStream: MediaStream): Promise<void> {
    const videoElement = this._videoElement.value;

    if (!videoElement) {
      this._autoMuted.value = false;
      this._autoPaused.value = false;
      this._loading.value = false;
      this._playing.value = false;
      this._state.value = ChannelState.Stopped;

      return;
    }

    videoElement.srcObject = mediaStream;

    const playPromise = videoElement.play();

    if (playPromise === undefined) {
      this._autoMuted.value = false;
      this._autoPaused.value = false;
      this._loading.value = false;
      this._playing.value = true;
      this._state.value = ChannelState.Playing;

      return;
    }

    return playPromise.then(() => {
      this._autoMuted.value = false;
      this._autoPaused.value = false;
      this._loading.value = false;
      this._playing.value = true;
      this._state.value = ChannelState.Playing;
    }).catch((e) => {
      const hasAudioTrack = !!mediaStream.getTracks().filter((track) => {
        return track.kind === 'audio';
      });
      const automaticallyMuteVideoOnPlayFailureOff = !SDK.automaticallyMuteVideoOnPlayFailure;

      if (automaticallyMuteVideoOnPlayFailureOff || videoElement.muted || !hasAudioTrack) {
        this._autoMuted.value = false;
        this._autoPaused.value = true;
        this._loading.value = false;
        this._playing.value = false;
        this._state.value = ChannelState.Paused;

        if (automaticallyMuteVideoOnPlayFailureOff) {
          this._logger.info('[%s] Paused video after play failed. Manual user action required.', this.streamId, e);
          videoElement.srcObject = null;

          return;
        }

        if (hasAudioTrack) {
          this._logger.info('[%s] Failed to play video. Manual user action required.', this.streamId, e);

          return;
        }

        this._logger.info('[%s] Failed to play muted video. Manual user action required.', this.streamId, e);

        return;
      }

      videoElement.muted = true;

      return videoElement.play()
        .then(() => {
          this._logger.info('[%s] Played video after auto muting. Manual user action required to unmute.', this.streamId);

          this._autoMuted.value = true;
          this._autoPaused.value = false;
          this._loading.value = false;
          this._playing.value = true;
          this._state.value = ChannelState.Playing;
        }).catch(e => {
          videoElement.muted = false;

          this._logger.info('[%s] Failed to play video. Manual user action required.', this.streamId, e);

          this._autoMuted.value = false;
          this._autoPaused.value = true;
          this._loading.value = false;
          this._playing.value = false;
          this._state.value = ChannelState.Playing;
        });
    });
  }

  private truncateIceServers(configuration: RTCConfiguration): RTCConfiguration {
    const iceServers: RTCIceServer[] = [];

    for (let i = 0; i < configuration.iceServers.length; i++) {
      const urls: string[] = [];

      for (let index = 0; index < 2; index++) {
        const url = configuration.iceServers[i].urls[index];

        if (url) {
          urls.push(configuration.iceServers[i].urls[index]);
        }
      }

      iceServers.push({
        urls: urls,
        username: configuration.iceServers[i].username,
        credential: configuration.iceServers[i].credential
      });
    }

    configuration.iceServers = iceServers;

    return configuration;
  }
}