/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {IAppender} from './IAppender';
import {LoggingLevel} from './Logger';

export default class ConsoleAppender implements IAppender {
  private readonly _threshold: LoggingLevel;

  log(logLevel: LoggingLevel, message: string, category: string, date: Date): void {
    if (logLevel < this._threshold) {
      return;
    }

    const fullMessage = `${date.toISOString()} [${category}] [${LoggingLevel[logLevel]}] ${message}`;

    if (logLevel < LoggingLevel.Warn) {
      console.log(fullMessage);

      return;
    }

    console.error(fullMessage);
  }

  constructor(threshold: LoggingLevel) {
    this._threshold = threshold;
  }
}