/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {LoggingLevel} from '../logger/Logger';
import VersionManager from '../version/VersionManager';

const requestSizeLimit = 8192;

interface ILogItem {
  timestamp: string;
  tenancy: string;
  level: string;
  category: string;
  message: string;
  sessionId: string;
  version: string;
  fullQualifiedName: string;
}

export default class TelemetryService {
  private readonly _url: string;
  private _logs: Array<ILogItem> = [];
  private _isSending: boolean;
  private _domain = location.hostname;

  constructor(url: string) {
    this._url = url;
  }

  push(tenancy: string, sessionId: string, logLevel: LoggingLevel, message: string, category: string, timestamp: Date): void {
    const logRecord = {
      timestamp: timestamp.toISOString(),
      tenancy,
      level: LoggingLevel[logLevel],
      category,
      message,
      sessionId,
      version: VersionManager.sdkVersion,
      fullQualifiedName: this._domain
    };

    if (logLevel < LoggingLevel.Error) {
      this._logs.push(logRecord);
    } else {
      this._logs.unshift(logRecord);
    }

    const ignored = this.sendLogsIfAble();
  }

  private async sendLogs(body: string): Promise<Response | void> {
    return await fetch(`${this._url}/telemetry/logs`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body
    });
  }

  private async sendLogsIfAble(): Promise<Response | void> {
    if (this._logs.length <= 0 || this._isSending) {
      return;
    }

    let numberOfLogsToSend = 0;
    let sizeOfLogsToSend = 0;

    this._isSending = true;

    const getLogSize = (log: ILogItem): number => Object.values(log)
      .reduce((sum, item) => sum + (item ? item.length : 0), 0);

    while (this._logs.length > numberOfLogsToSend && getLogSize(this._logs[numberOfLogsToSend]) + sizeOfLogsToSend < requestSizeLimit) {
      sizeOfLogsToSend += getLogSize(this._logs[numberOfLogsToSend]);
      numberOfLogsToSend++;
    }

    if (!numberOfLogsToSend) {
      this._logs[numberOfLogsToSend].message = this._logs[numberOfLogsToSend].message.substring(0,
        getLogSize(this._logs[numberOfLogsToSend]) + (requestSizeLimit - getLogSize(this._logs[numberOfLogsToSend])));
      numberOfLogsToSend = 1;
    }

    const body = JSON.stringify({records: this._logs.slice(0, numberOfLogsToSend)});

    this._logs = this._logs.slice(numberOfLogsToSend);

    return this.sendLogs(body).then(response => {
      this._isSending = false;

      const ignored = this.sendLogsIfAble();

      return response;
    }).catch(() => {
      this._isSending = false;

      const ignored = this.sendLogsIfAble();
    });
  }
}