/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import PrivacyMode from '../dom/PrivacyMode';
import {TelemetryLevel} from '../metrics/MetricsConfiguration';

export default class TelemetryDefault {
  static get defaultTelemetryLevel(): TelemetryLevel {
    return PrivacyMode.isPrivate ? TelemetryLevel.Essential : TelemetryLevel.All;
  }
}