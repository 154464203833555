/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import TelemetryDefault from '../metrics/TelemetryDefault';
import TelemetryLevelMapping from '../metrics/TelemetricLevelMapping';
import LoggingLevelMapping from '../logger/LoggingLevelMapping';
import LoggerDefaults from '../logger/LoggerDefaults';

export default class ConfigurationParameterReader {
  getStringValue(name: string): string {
    return this.readConfigurationParameterValue(name) ||
      this.readConfigurationParameterURIValue(`${name}-uri-parameter-name`) ||
      this.defaultStringValue[name];
  }

  getBooleanValue(name: string): boolean {
    const value = this.readConfigurationParameterValue(name);

    if (value) {
      return value === 'true' || value === '1' || value === 'On';
    }

    const valueFromUri = this.readConfigurationParameterURIValue(`${name}-uri-parameter-name`);

    if (valueFromUri) {
      return value === 'true' || value === '1' || value === 'On';
    }

    return this.defaultBooleanValue[name] === true;
  }

  private get defaultStringValue(): { [name: string]: string} {
    return {
      'phenix-metrics-level': TelemetryLevelMapping.convertTelemetryLevelToTelemetryLevelType(TelemetryDefault.defaultTelemetryLevel),
      'phenix-logging-level': LoggingLevelMapping.convertLoggingLevelToLoggingLevelType(LoggerDefaults.defaultLoggingLevel),
      'phenix-console-logging-level': LoggingLevelMapping.convertLoggingLevelToLoggingLevelType(LoggerDefaults.defaultConsoleLoggingLevel),
      'phenix-telemetry-logging-level': LoggingLevelMapping.convertLoggingLevelToLoggingLevelType(LoggerDefaults.defaultTelemetryLoggingLevel),
      'phenix-channel-token': '',
      'phenix-uri': '',
      'phenix-base-uri': ''
    };
  }

  private get defaultBooleanValue(): { [name: string]: boolean } {
    return {'phenix-automatically-retry-on-failure': true};
  }

  private readConfigurationParameterValue(name: string): string {
    const metaValue = document.querySelector(`meta[name='${name}']`);

    if (!metaValue) {
      return;
    }

    const value = metaValue.getAttribute('value');

    if (!value) {
      return;
    }

    return value;
  }

  private readConfigurationParameterURIValue(name: string): string {
    const valueURIParameterName = this.readConfigurationParameterValue(name);

    if (!valueURIParameterName) {
      return;
    }

    const value = new URLSearchParams(location.search).get(valueURIParameterName) || undefined;

    if (!value) {
      return;
    }

    return value;
  }
}